.menu {
    font-size: 14px;
    background-color: #fff;
    border-radius: 2px;
    padding: 5px 0 5px 0;
    width: 150px;
    height: auto;
    margin: 0;
  /* use absolute positioning  */
    position: absolute;
    list-style: none;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
    opacity: 1;
    transition: opacity 0.5s linear;
    z-index: 99999;
    li {
      margin-left: 5px;
    }
  }

  @media (prefers-color-scheme: dark) {
    .menu {
      background-color: #4f4f4f;
      color: #fff;
      box-shadow: 0 10px 20px 0 rgba(256,256,256,.05);
    }
  }

  @media (prefers-color-scheme: light) {
    .menu {
      background-color: #fff;
      color: #000;
      box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
    }
  }