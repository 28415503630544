@mixin filledStyle() {
    background-color: white;
    color: black;
  }
  
  @mixin alignCenter() {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .DragDrop {
    width: 100%;
    height: 100vh;
    @include alignCenter();
    
    &-File {
      width: 400px;
      height: 200px;
      border: 2px solid white;
      border-radius: 10px;
      
      @include alignCenter();
      cursor: pointer;
      transition: 0.12s ease-in;
  
      &-Dragging {
        @include filledStyle();
      }
    }
  
    &-Files {
      margin-top: 1rem;
  
      & > div {
        width: 300px;
        padding: 8px;
        border: 1px solid white;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }
  
      &-Filter {
        cursor: pointer;
        
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }